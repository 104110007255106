import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Img from "gatsby-image";
import { getFluid } from "../../util/helper";

const fmTitle = "Ellenzéki győzelem vagy Fidesz 2/3?";
const fmSlug = "ellenzeki-gyozelem-vagy-fidesz-ketharmad";
const fmDate = "2022. március 25.";

export const Common = () => (
	<React.Fragment>
		<div>
			Ahogy közeledik április 3, úgy hallunk egyre több erős mondást az elemzőktől (legutóbb pl. Mérő László{" "}
			<a
				className="link"
				href="https://telex.hu/kult/2022/03/19/mero-laszlo-orban-viktor-ketharmadot-hetszentseg-hogy-nem-fog-kapni"
			>
				fejtette ki
			</a>{" "}
			– számításunk szerint tévesen –, hogy Fidesz biztosan nem szerez 2/3-ot). És ugyan 2 héttel a választások
			előtt a közvélemény-kutatások átlaga szerint Fidesz-KDNP{" "}
			<a
				className="link"
				href="https://voxpopuli.444.hu/2022/03/16/miert-nincs-friss-valasztasi-kozvelemeny-kutatas"
			>
				vezet
			</a>{" "}
			4.5%-kal (ld. a Vox Populi számítását), a felméréseket közelebbről megnézve kiderül, hogy a választások
			közel sem tekinthetők lefutottnak:
			<ul className="mt-2 ul">
				<li>
					Egyrészt a közvélemény-kutatásoknak, illetve az azokból számított átlagoknak is van hibája: A
					fivethirtyeight.com számítása{" "}
					<a
						className="link"
						href="https://fivethirtyeight.com/features/why-was-the-national-polling-environment-so-off-in-2020"
					>
						alapján
					</a>{" "}
					még az Egyesült Államokban is 3% volt a közvélemény-kutatási átlagok hibája az elmúlt 25 év
					elnökválasztásait nézve. Ráadásul az orosz-ukrán háború miatt a helyzet itthon a szokásosnál is
					ingatagabb.
				</li>
				<li>
					Másrészt míg a közvéleménykutatások alapvetően a listák támogatottságát mérik, a választás eredménye
					nem listán, hanem az egyéni körzetekben fog eldőlni, ahol minimális szavazatkülönbség nagy
					mandátumbeli különbséghez vezethet. Adott esetben pl. 25 ezer jól irányzott szavazat 16 mandátumnyi,
					azaz 8%-al több mandátumot is szerezhet egy adott pártnak. Viszont ugyanez a 25 ezer szavazat az
					országos listán fél százalékot sem jelent, tehát a közvélemény-kutatásokban kerekítési hibának
					számít, azaz nem jelenik meg.
				</li>
			</ul>
		</div>
	</React.Fragment>
);

export const EllenzekiGyozelemVagyFideszKetharmadAbstract = () => (
	<section>
		<Link to={`/blog/${fmSlug}`}>
			<h1 className="h1 cursor-pointer">{fmTitle}</h1>
		</Link>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<div className="mt-4">
			<Common />
		</div>
		<div className="mt-4">
			<div className="flex justify-between">
				<div></div>
				<div>
					<Link className="link" to={`/blog/${fmSlug}`}>
						tovább ⟶
					</Link>
				</div>
			</div>
		</div>
	</section>
);

interface ArticleProps {
	fluids: { [key: string]: any };
}

const FullArticle = (props: ArticleProps) => (
	<section>
		<h1 className="h1">{fmTitle}</h1>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<h2 className="h2 mt-4">Valós esélye van az ellenzéki győzelemnek, de az újabb Fidesz-KDNP kétharmadnak is</h2>
		<Common />
		<p className="mt-4">
			A következőkben azt nézzük meg, hogy milyen valódi összefüggés van a közvélemény kutatások és a választás
			eredményei között. Megmutatjuk, hogy bár a Fidesz a felmérések alapján nyerésre áll, és valószínűleg
			elveszti a kétharmados többségét, elemzésünk szerint a jelenlegi adatok alapján valós esély van az ellenzéki
			győzelemre (egyszerű többséggel), illetve közel ugyanakkora esély van az újabb Fidesz 2/3-ra is.
		</p>
		<h2 className="h2 mt-4">A közvéleménykutatások hibája</h2>
		<p className="mt-4">
			A hazai választásokról és az ahhoz közvélemény-kutatásokról pontosságáról kevés adat áll rendelkezésünkre. A
			Vox Populi blog számításai{" "}
			<a className="link" href="https://voxpopuli.444.hu/2022/02/20/valasztasi-meglepetes-keszul-1-resz">
				szerint
			</a>{" "}
			a közvéleménykutatások hibasávja 24 százalék széles, ami 6%-os szórásnak felel meg. Mi ennél
			konzervatívabban leszünk, és 3%-os szórást fogunk használni a közvélemény kutatások átlagának hibájára:
			fivethirtyeight.com{" "}
			<a
				className="link"
				href="https://fivethirtyeight.com/features/why-was-the-national-polling-environment-so-off-in-2020/"
			>
				cikke
			</a>{" "}
			szerint az Egyesült Államokban a képviselőházi illetve az elnökválasztások során használt
			közvéleménykutatási átlagok hibája 3% körül van. Ha ezt a hibát{" "}
			<a className="link" href="https://en.wikipedia.org/wiki/68%E2%80%9395%E2%80%9399.7_rule">
				alkalmazzuk
			</a>{" "}
			a bevezetőben említett 4.5%-os Fidesz-előnyre, akkor azt kapjuk, hogy 95%-os valószínűséggel a várható
			eredmény valahol 11.5% Fidesz-előny és 1.5%-os Összefogás-előny között lesz.
		</p>
		<p className="mt-4">
			<iframe
				title="Országos listás eredmény várható eloszlása"
				aria-label="chart"
				id="datawrapper-chart-zqCBb"
				src="https://datawrapper.dwcdn.net/zqCBb"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<p className="mt-4">
			De vajon ez azt jelenti, hogy a választás eredménye is ugyanilyen nagy – az ábrán látható eloszláshoz
			hasonló – eséllyel lesz Fidesz-győzelem? Korántsem! Hiszen az eredményt az egyéni győzelmek döntik el, tehát
			a pontosabb előrejelzéshez az egyéni győzelmeket kell megbecsülnünk.
		</p>
		<h2 className="h2 mt-4">Mi az összefüggés az országos listás szavazat és a parlamenti mandátumok között?</h2>
		<h3 className="h3 mt-4">Egyszerű mandátumbecslés</h3>
		<p className="mt-4">
			Felállítottunk egy egyszerű modellt, ami megbecsüli, hogy adott országos listás szereplés esetén milyen lesz
			mandátumbeli eredmény várható. A modell a 2018-as választás eredményeiből indul ki (
			<Link className="link" to={`/blog/${fmSlug}#modell-leiras`}>
				a teljes leírás itt olvasható
			</Link>
			).
		</p>
		<p className="mt-4">
			A mandátum eredménybecslésénél az a nehézség, hogy ahhoz nem elég tudni az országos eredményt, hanem mind a
			106 egyéni körzet kimenetelét meg kell tudni jósolni, hiszen az egyéni szavazatok{" "}
			<a className="link" href="https://mostvasarnap.hu/blog/egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas">
				határozzák meg
			</a>{" "}
			a mandátumok 70%-át. Az előrejelzésnél tehát fontos figyelembe venni a szavazatok várható földrajzi
			eloszlását.
		</p>
		<p className="mt-4">
			A modellt az elmúlt választások adatain teszteltük: a 2006-2018 közötti parlamenti választások illetve a
			2019-es EP választás szavazatainak az eloszlását felhasználva megnéztük, hogy a mostani választási
			rendszerben, illetve kétpólusú felosztásban, azaz teljes ellenzéki összefogással milyen országos listás
			eredményhez milyen mandátumkiosztás tartozott. (Fontos megjegyezni, hogy ez nem ugyanaz, mint az adott
			választások tényleges múltbeli eredménye: mert választásokat vagy nem a mostani választási rendszerben
			rendezték (2006, 2010, 2019 EP), vagy nem kétpólusú volt a választás (2010, 2014, 2018). Például 2018-ban
			Fidesz kétharmad helyett teljes ellenzéki összefogás esetén csak minimális többsége lett volna a Fidesznek a
			parlamentben.)
		</p>
		<p className="mt-4">
			<iframe
				title="Parlamenti mandátumarány becslés"
				aria-label="chart"
				id="datawrapper-chart-fB3tY"
				src="https://datawrapper.dwcdn.net/fB3tY"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<p className="mt-4">
			A modell eredményét a vörös vonal jelzi az ábrán. Az, hogy a közelítés jól működik, az abból is látszik,
			hogy a modell által kalkulált eredmények (vörös vonal) szépen illeszkednek az elmúlt választások
			eredményeiből számolt adatpontokra (zöld körök). Azaz már ez az egyszerű is modell jól követi le 15 év
			választásai alatt tapasztalt szavazói változásokat, pontosabban annak a parlamenti mandátum számításokra
			való hatását.
		</p>
		<h3 className="h3 mt-4">Összetett mandátumbecslés</h3>
		<p className="mt-4">
			Az elmúlt választásokon a valóságban mások voltak a csataterek, mint amit a fenti számolásnál használtunk,
			hiszen a számításnál a mostani választási rendszert és teljes ellenzéki összefogást feltételezünk. Ez azért
			fontos, mert a két oldal a választási kampányát mindig az adott helyzetre optimalizálja: azokban a
			körzetekben kampányol legintenzívebben, amelyikben a legnagyobb esélye van fordítani. A kérdés tehát az,
			hogy egy jól célzott kampánnyal mennyivel tud eltérni az eredmény az egyszerű modell által becsült
			eredménytől.
		</p>
		<p className="mt-4">
			A szoros körzetekben, ahol nagyon kicsi a különbség az ellenzék és a kormánypárt jelöltjei szavazatai
			között, az egyéni győzelmei különösen erősen el tudják vinni az végeredményt egy erős kormánypárti vagy akár
			erős ellenzéki dominancia irányába. Hogy ezeket is belefoglaljuk az előrejelzésbe, szükségünk van egy
			összetettebb modellre, ami az egyéni körzetek sajátosságait is magában foglalja.
		</p>
		<p className="mt-4">
			Az összetett modellben megnéztük, hogy az Összefogás illetve a Fidesz hány szoros körzetet tud átbillenteni
			úgy, hogy az még ne látszódjon meg az országos listás eredményen. Vettünk mindkét esetben{" "}
			<Link className="link" to={`/blog/${fmSlug}#25ezer-szavazat`}>
				25 ezer szavazatot
			</Link>
			, és kiosztottuk legszorosabb vesztes körzetek között úgy, hogy azok éppen átbillenjenek. 25 ezer szavazat
			kevesebb mint fél százaléka az összes leadott szavazatnak, így gyakorlatilag fel sem tűnik. Viszont 25 ezer
			szavazat általában 10-15 szoros körzetet is át tud billenteni, ami még a kompenzációs hatás után is 8-12
			mandátummal tudja növelni az adott oldal eredményét.
		</p>
		<p className="mt-4">
			Például, ha döntetlen az országos eredmény (50% Fidesz-KDNP listás szavazat, 50% Összefogás listás
			szavazat), akkor az egyszerű modell alapján a Fidesz-KDNP nyeri a választásokat 54-46%os mandátum-aránnyal,
			köszönhetően a levélszavazók és nemzetiségi listáról szerzett 5 mandátumnak, illetve az egyéni körzetek
			kialakítása miatt fennálló 10-12 mandátumos előnyének. Viszont – és ezt mutatja meg a következő, összetett
			modell –, ha az ellenzék jól hajrázik a szoros körzetekben, akkor 25 ezer jólirányzott szavazattal 8%-os
			mandátumbeli hátrányt 6%-os előnyre tudja fordítani.
		</p>
		<p className="mt-4">
			Az ábrán kék vonallal jelöljük az eredményt abban az esetben, amikor az Összefogás tudja 25 ezer szavazattal
			a szoros körzeteket átbillenteni, és narancssárga vonallal azokat az eseteket, amikor a Fidesznek sikerül
			ugyanez.
		</p>
		<p className="mt-4">
			<iframe
				title="Parlamenti mandátumarány becslés"
				aria-label="chart"
				id="datawrapper-chart-tSTNv"
				src="https://datawrapper.dwcdn.net/tSTNv"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "590px" }}
			></iframe>
		</p>
		<p className="mt-4">
			A módszerünk által számított várható eredményeket a kék és narancs vonal közötti sáv és függőleges vörös sáv
			metszete tartalmazza. Az ábrán látszik, hogy az ellenzék reálisan megnyerheti a választást, ha alul mérik a
			közvélemény kutatások és a szoros körzetekben jól teljesít. Ezt mutatja az a terület a vörös sávon belül,
			ahol a kék vonal a vízszintes szaggatott fekete vonal felett van, azaz az ellezéki mandátumszáma átlépi a
			parlamenti mandátumok 50%-át.
		</p>
		<p className="mt-4">
			Hasonlóan lehet a modellből leolvasni a Fidesz 2/3 esélyeit, ami az a terület, ahol a narancssárga vonal a
			vörös sávon belül a Fidesz kétharmadot reprezentáló vízszintes, szaggatott narancssárga vonal alá esik. A
			kétharmad megtartásához az kell, hogy a Fidesz túlteljesítse a közvéleménykutatások által mért eredményét,
			és jól szerepeljen a billegő körzetekben. Minden más kimenetel a kormánypárt egyszerű többségét jelzi.
		</p>
		<p className="mt-4">
			Érdemes megjegyezni, hogy a közvélemény-kutatások hibahatárát (a vörös sáv szélességét) és a megfelelően
			lefolytatott célzott kampány hatását (kék és narancs vonal függőleges távolságát) nem tudjuk pontosan
			megbecsülni. Ezek a sávok valóságban még szélesebbek is lehetnek, növelve ezzel az extrém események
			(Ellenzéki győzelem, Fidesz ⅔) bekövetkezésének valószínűségét.
		</p>
		<p className="mt-4">
			Összefoglalva tehát: A modellünk alapján nagy valószínűséggel a Fidesz fog nyerni, de elveszti a 2/3-os
			többségét. Viszont nem kizárt, hogy egy jó hajrával az ellenzéki Összefogás nyerhet, ha a
			közvélemény-kutatók alábecsülték az ellenzék népszerűségét és a szoros körzetekben kiemelkedően
			teljesítenek. Némileg kisebb a valószínűsége, de szintén nem kizárt, hogy a közvélemény-kutatók
			alulbecsülték a Fidesz népszerűségét és akkor egy jó hajrával 2/3-ot tudnak ismét szerezni.
		</p>
		<h2 className="h2 mt-4" id="modell-leiras">
			Modell leírás
		</h2>
		<p className="mt-4">
			Meglepő módon a 2018-as választások eredményeiből egy egyszerű egyparaméteres modellel nagyon pontosan meg
			lehet közelíteni a 2006-2014 közötti parlamenti választások illetve a 2019-es EP választás eredményeiből a
			mostani választási rendszerben illetve a mostani kétpólusú felállásban számolt mandátumbeli eredményeket. Az
			alábbi tábláról leolvasható, hogy az adott választáson leadott szavazatok földrajzi eloszlásából számított
			és a 2018-as eredményekből kalibrált mandátumkiosztás között kevesebb mint 2% különbség van.
		</p>
		<p className="mt-4">
			A kalibráció úgy zajlik, hogy vesszük a 2018-as eredményeket, és megkeressük azt a számot, amellyel az
			ellenzéki szavazatokat minden szavazókörben megszorozva megkapjuk az adott választás országos listás
			eredményét. Például a 2019-es EP választás esetén a Fidesz 11%-kal nyert országosan listán, és nekünk
			22%-kal kellett ezért a 2018-as választások eredményeiben az ellenzék szavazatait csökkenteni, hogy az
			2018-as választásokon elért 2%-os előnyből 11%-os hátrány legyen (1 - 22% = 0.78-as szorzó).
		</p>
		<p className="mt-4 md:hidden">
			<iframe
				title="Mandátumeloszlás előrejelzése a listás eredményből"
				aria-label="chart"
				id="datawrapper-chart-OiYr4"
				src="https://datawrapper.dwcdn.net/OiYr4"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "730px" }}
			></iframe>
		</p>
		<p className="mt-4 hidden md:block">
			<iframe
				title="Mandátumeloszlás előrejelzése a listás eredményből"
				aria-label="chart"
				id="datawrapper-chart-OiYr4"
				src="https://datawrapper.dwcdn.net/OiYr4"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "500px" }}
			></iframe>
		</p>
		<h2 className="h2 mt-4" id="25ezer-szavazat">
			25 ezer szavazat
		</h2>
		<p className="mt-4">
			Az április választásokon várhatóan 5-6 millió szavazó fog résztvenni a külhoni magyarok levélszavazatait nem
			számítva. 25 ezer szavazat ennek kevesebb mint fél százaléka, azaz kerekítési hiba. Viszont 25 ezer szavazat
			egyéniben könnyen 15-20 egyéni körzet átfordításához is elegendő lehet, ami még a kompenzációs hatás után is
			12-15 plusz mandátumot is jelenthet. A következő táblázatban példaként megmutatjuk, hogy 25 ezer szavazat
			mely körzeteket tudta volna átfordítani, ha már 2018-ban megvalósult volna a teljes ellenzéki összefogás.
		</p>
		<p className="mt-4 md:hidden">
			<iframe
				title="Hány körzetet lehet átfordítani .5%-nyi szavazattal?"
				aria-label="chart"
				id="datawrapper-chart-VQ5R7"
				src="https://datawrapper.dwcdn.net/VQ5R7"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "1400px" }}
			></iframe>
		</p>
		<p className="mt-4 hidden md:block">
			<iframe
				title="Hány körzetet lehet átfordítani .5%-nyi szavazattal?"
				aria-label="chart"
				id="datawrapper-chart-VQ5R7"
				src="https://datawrapper.dwcdn.net/VQ5R7"
				scrolling="no"
				frameBorder="0"
				style={{ width: "100%", border: "none", minWidth: "100% !important", height: "1150px" }}
			></iframe>
		</p>
		<p className="mt-4">
			Érdekes megfigyelni, hogy 25 ezer szavazat esetén mindkét oldalon az utolsó átfordított körzetekben 7%
			körüli idioszinkratikus változásra van szükség az átbillentéshez. Ez nagyjából megegyezik a két választás
			közötti OEVK szintű eredmények változásának szórásával. Azaz ennyi eltérés a változásban az országos listás
			eredményhez képest még átlagosnak számít.
		</p>
	</section>
);

export default () => {
	// const data = useStaticQuery(graphql`
	// 	query ServicesPageQuery {
	// 		allFile(filter: { relativePath: { in: ["vegyes-rendszer.png", "szavazat-mandatum.png"] } }) {
	// 			edges {
	// 				node {
	// 					relativePath
	// 					childImageSharp {
	// 						fluid(maxWidth: 1024) {
	// 							...GatsbyImageSharpFluid
	// 							...GatsbyImageSharpFluidLimitPresentationSize
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	const fluids = {
		// vegyesRendszer: getFluid(data.allFile.edges, "vegyes-rendszer.png"),
		// szavazatMandatum: getFluid(data.allFile.edges, "szavazat-mandatum.png"),
	};

	return (
		<Layout>
			<SEO title={fmTitle} />
			<div className="container px-4 py-8">
				<FullArticle fluids={fluids} />
			</div>
		</Layout>
	);
};
